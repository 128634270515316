<template>
  <div class="container flex flex-col flex-1">
    <b-overlay
      :show="loading"
      :opacity="1"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
      blur="none"
      class="flex flex-col flex-1"
      overlay-tag="div"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-1 flex-col justify-center items-center" v-if="!loading">
          <b-row v-if="step===1" class="w-full justify-center">
            <b-col md="9" lg="6" align-self="center">
              <validation-observer
                tag="div"
                class="-mt-28"
                ref="registerForm"
                @keyup.enter="validateForm()"
              >
                <div class="txt-pri-900 text-center mb-4">
                  <h1 class="uppercase mb-0">ĐĂNG KÝ KHÁM BỆNH</h1>
                  <div class="h4 text-capitalize mb-0">{{ selectedWs.name }}</div>
                </div>
                <b-form>
                  <b-form-group class="mb-2">
                    <validation-provider #default="{ errors }" name="Họ và tên" rules="required">
                      <label for="text-password">
                        Họ và tên
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input v-model="fullName" />
                      <small v-if="errors[0]" class="text-danger">{{ 'Nhập họ và tên' }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group class="mb-2">
                    <validation-provider
                      #default="{ errors }"
                      name="Số điện thoại"
                      rules="required"
                    >
                      <label for="text-password">
                        Số điện thoại
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input type="number" v-model="phone" />
                      <small v-if="errors[0]" class="text-danger">{{ 'Nhập số điện thoại' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
              <div>
                <div>
                  <b-button
                    :disabled="loading"
                    style="width: 100%;
        background-color : #20409B;"
                    @click="validateForm()"
                  >Tiếp tục</b-button>
                </div>
                <div class="text-center py-2">
                  <a
                    class="btn d-inline-block hover:underline"
                    href="javascript:history.back()"
                    role="button"
                  >
                    <i class="el-icon-back align-middle me-1" />
                    <span class="align-middle">Quay lại</span>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="step===2" class="w-full justify-center">
            <b-col md="9" lg="6" align-self="center">
              <div class="-mt-28">
                <div class="txt-pri-900 text-center mb-4">
                  <h1 class="uppercase mb-0">ĐĂNG KÝ KHÁM BỆNH</h1>
                  <div class="h4 text-capitalize mb-0">{{ selectedWs.name }}</div>
                </div>
                <div class="text-center">
                  <p class="font-bold mb-0">XÁC THỰC SỐ ĐIỆN THOẠI CỦA BẠN</p>
                  <p class="fs-13">
                    Nhập mã xác thực được gửi đến SĐT
                    <span class="txt-pri-900 font-bold">{{ phone }}</span>
                  </p>
                </div>
                <div class="d-flex justify-content-center">
                  <OtpInput
                    ref="otpInput"
                    input-classes="otp-input text-center"
                    separator="&nbsp;"
                    :num-inputs="4"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-complete="handleOnComplete"
                  ></OtpInput>
                </div>
                <div class="text-center" v-if="time_out === 0">
                  <p class="fs-13">Bạn chưa nhận được mã xác thực?</p>
                </div>
                <div class="text-center">
                  <button
                    v-if="otpCount < 5"
                    @click="getOTP(true)"
                    class="btn fw-500 fs-15"
                    :class="time_out > 0 ? '' : 'bg-pri text-white'"
                    :disabled="time_out > 0"
                  >
                    GỬI LẠI MÃ OTP
                    <span v-if="time_out">
                      <span>{{ convertMinutesSeconds(time_out) }}</span>s
                    </span>
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  BButton,
  BFormGroup,
  BRow,
  BFormInput,
  BForm,
  BCol,
  BOverlay
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  isPhoneNumber
} from '../../../../src/utils/validations/validations'
import OtpInput from '@bachdgvn/vue-otp-input'
import moment from 'moment'
import appUtils from '../../../utils/appUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalRegisterFormOTP',
  components: {
    BOverlay,
    OtpInput,
    BButton,
    BFormGroup,
    BRow,
    BFormInput,
    BForm,
    BCol,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      dial_code: 84,
      isPhoneNumber,
      required,
      fullName: null,
      phone: null,
      time_out: 0,
      step: 1,
      loading: true,
      otpCount: 0,
      user_id: null,
      isRegister: false,
      interval: null
    }
  },
  beforeMount () {
    if (!this.$user) return
    const isRegister = !!this.$user?.person
    const phone =
      this.$user?.person?.phone || this.$user?.phone || this.$user?.username
    const fullName = this.$user?.name || this.$user?.person?.name

    this.$router
      .replace({
        name: 'FormInformation',
        query: {
          isRegister: isRegister,
          phone: phone,
          fullName: fullName,
          pId: this.$user?.person?.id
        }
      })
      .catch(() => {})
  },
  mounted () {
    this.interval = setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  computed: {
    ...mapGetters('registerMedical', ['defaultWs', 'selectedWs'])
  },
  methods: {
    validateForm () {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const self = this
          if (self.validatePhone()) {
            self.sendAmplitude('Enter phone', {
              Phone: this.phone,
              Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
            })
            self.getOTP()
          } else {
            window.$toast.open({
              message: 'Vui lòng kiểm tra lại số điện thoại đã nhập',
              type: 'error'
            })
          }
        }
      })
    },
    validatePhone () {
      if (!this.phone) return
      const firstC = this.phone.charAt(0)
      if (parseInt(firstC) !== 0) this.phone = '0' + this.phone
      const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      return this.phone.match(phoneno)
    },
    handleOnComplete (value) {
      this.loading = true
      console.log('OTP completed: ', value)
      this.otpVerify(value)
    },
    async getOTP (resend) {
      const self = this
      this.loading = true
      const params = {
        dial_code: this.dial_code,
        username: this.phone
      }
      if (resend) {
        self.sendAmplitude('Resend OTP', {
          Phone: this.phone,
          Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
      }
      await self.$rf
        .getRequest('GuestRequest')
        .getOTP(params)
        .then(resp => {
          if (resp?.data) {
            self.user_id = resp.data.id
          }
          self.step = 2
          self.setIntervalTimeOut()
        })
        .catch(e => {
          console.log(e)
          window.$toast.open({
            message: 'Vui lòng kiểm tra lại số điện thoại đã nhập',
            type: 'error'
          })
          self.user_id = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    async otpVerify (value) {
      const self = this
      const params = {
        user_id: self.user_id,
        otp: value,
        web: 1
      }
      await self.$rf
        .getRequest('GuestRequest')
        .otpVerify(params)
        .then(resp => {
          appUtils.setLocalToken(resp?.data?.access_token)
          self.getUserInfo(value)
        })
        .catch(e => {
          if (e.response.status === 401) {
            window.$toast.open({
              message:
                'Bạn đã nhập sai 5 lần. Vui lòng chờ trong 5 phút dể thử lại!',
              type: 'error'
            })
            setTimeout(() => {
              window.location.reload()
            }, 100)
          } else {
            if (this.otpCount < 4) {
              window.$toast.open({
                message:
                  'Mã OTP không chính xác, nhập sai 5 lần mã OTP sẽ hết hiệu lực',
                type: 'error'
              })
              this.otpCount += 1
              console.log(this.otpCount)
            } else {
              window.$toast.open({
                message:
                  'Bạn đã nhập sai 5 lần. Vui lòng chờ trong 5 phút dể thử lại!',
                type: 'error'
              })
              setTimeout(() => {
                window.location.reload()
              }, 100)
            }
          }

          self.sendAmplitude('Enter OTP', {
            Phone: this.phone,
            Status: 'Fail',
            Otp: value,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
        })
    },
    async getUserInfo (value) {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then(resp => {
          appUtils.setLocalUser(resp.data)
          console.log(resp.data)
          self.sendAmplitude('Enter OTP', {
            Phone: this.phone,
            Status: 'Success',
            Otp: value,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
          if (resp.data.person) {
            self.isRegister = true
          }
          self.$router.replace({
            path: '/form-information',
            query: {
              isRegister: self.isRegister,
              phone: self.phone,
              fullName: self.fullName,
              pId: resp.data.person?.id || ''
            }
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
    convertMinutesSeconds (time) {
      const minutes = Math.floor(time / 60)
      const seconds = time - minutes * 60
      return `${minutes}:${('0' + seconds).slice(-2)}`
    },
    setIntervalTimeOut () {
      const self = this
      self.time_out = 30
      clearInterval(self.interval_time_out)
      self.interval_time_out = setInterval(() => {
        if (self.time_out > 0) {
          self.time_out--
        } else {
          clearInterval(self.interval_time_out)
        }
      }, 1000)
    }
  },
  destroyed () {
    clearTimeout(this.interval)
  }
}
</script>
<style scoped>
.form {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
